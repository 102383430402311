<template>
    <div class="page-facility hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h2 class="is-size-2 has-text-centered">{{ facility.name }}</h2>
            </div>

            <div class="column is-12 box" v-if="!isMobile()">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Year of Experiment</th>
                        </tr>
                    </thead>
                    <GenericProjectItem 
                        v-for="project in facility.facility_datasets" 
                        v-bind:key="project.id"
                        v-bind:project="project"
                        v-bind:page="'Facility'"
                    />
                    <tbody>

                    </tbody>
                </table>
            </div>

            <ProjectBox v-else v-for="project in facility.facility_datasets" v-bind:key="project.id"
                v-bind:project="project" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import { isMobile, getLocalStorage, sort } from '@/assets/methods.js'
import GenericProjectItem from '@/components/GenericProjectItem'
import ProjectBox from '@/components/ProjectBox'

export default {
    name: 'Facility',
    components: {
        GenericProjectItem,
        ProjectBox,
    },
    data() {
        return {
            facility: {
                facility_datasets: []
            },
        }
    },
    mounted() {
        this.getFacility()
    },
    methods: {
        isMobile,
        async getFacility() {
            this.$store.commit('setIsLoading', true)

            const facility_slug = this.$route.params.facility_slug

            var datasets = getLocalStorage('datasets')
            
            if (datasets !== null) {
                this.facility.facility_datasets = datasets.filter(dataset => dataset.experiment_facility.find(facility => facility.slug === facility_slug))

                var facility = this.facility.facility_datasets[0].experiment_facility.find(facility => facility.slug === facility_slug)
                
                sort(this.facility.facility_datasets)

                this.facility.name = facility.name
            
            } else {
                
                await axios
                    .get(`/api/v1/facility/${facility_slug}`)
                    .then(response => {
                        this.facility = response.data
                        sort(this.facility.facility_datasets)
                        
                    })
                    .catch(error => {
                        console.log(error)

                        toast({
                            message: 'Something went wrong. Please try again',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })
            }

            document.title = this.facility.name + ' | ' + this.$store.state.appName
            this.$store.commit('setIsLoading', false)
        }
    },
}
</script>