<template>
    <div class="page-all-projects hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Showing {{ visibleProjects.length }} out of {{ allProjects.length }} Datasets</h1>
            </div>

            <div class="column is-12 box" v-if="!isMobile()">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Keywords</th>
                            <th>Date of Publication</th>
                        </tr>
                    </thead>
                    <ProjectItem v-for="project in visibleProjects" v-bind:currentPage="currentPage" v-bind:key="project.id"
                        v-bind:project="project" />
                    <tbody>

                    </tbody>
                </table>
            </div>

            <ProjectBox v-else v-for="project in visibleProjects" v-bind:currentPage="currentPage" v-bind:key="project.id"
                v-bind:project="project" />

            <Pagination v-bind:projects="allProjects" v-on:page:update="updatePage" v-bind:currentPage="currentPage"
                v-bind:pageSize="pageSize" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import { isMobile } from '@/assets/methods.js'
import ProjectItem from '@/components/ProjectItem'
import ProjectBox from '@/components/ProjectBox'
import Pagination from '@/components/Pagination'

export default {
    name: "alldatasets",
    components: {
        ProjectItem,
        ProjectBox,
        Pagination,
    },
    data() {
        return {
            allProjects: [],
            currentPage: 0,
            pageSize: 20,
            visibleProjects: [],
        }
    },
    mounted() {
        document.title = 'All Datasets | ' +  this.$store.state.appName

        this.getAllProjects()
    },
    methods: {
        isMobile,
        async getAllProjects() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/all-datasets/`)
                .then(response => {
                    this.allProjects = response.data
                })
                .catch(error => {
                    console.log(error)

                    toast({
                        message: 'Something went wrong. Please try again',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                })

            this.$store.commit('setIsLoading', false)

            // get only visible datasets per page
            this.updateVisibleProjects()
        },
        updatePage(pageNumber) {
            this.currentPage = pageNumber;
            this.updateVisibleProjects();
        },
        updateVisibleProjects() {
            this.visibleProjects = this.allProjects.slice(
                this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize
            );

            // go back a page
            if (this.visibleProjects.length == 0 && this.currentPage > 0) {
                this.updatePage(this.currentPage - 1);
            }
        },
    },
}
</script>